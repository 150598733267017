import axios, { AxiosError } from "axios";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { getAxiosRequestConfig } from "../../../utilities/getAxiosRequestConfig";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { Constants } from "../../../utilities/Constants";
export interface ISubmission {
    readonly _id: string;
    readonly created: string;
    readonly completed: string;
    readonly image: string;
    readonly alias: string;
    readonly _teamId: string;
    readonly eventCode: string;
    readonly status: "success" | "pending" | "fail";
    readonly saveLogs: string | null;
    readonly validationStatus: {
        readonly isValid: boolean | null;
        readonly gameJobId: string;
    } | null;
}

const getSubmissionById = async (token: string | null, submissionId: string) => {
    const config = getAxiosRequestConfig(token);
    const uri = `${Constants.ApiRoot}/submission/${submissionId}`;
    try {
        const { data } = await axios.get<ISubmission>(uri, config);
        return data;
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError?.response?.status === 404) {
            return undefined;
        }
        throw error;
    }
};

export const useSubmission = (submissionId: string) => {
    const { token } = useContext(AuthContext);

    return useQuery(["submission", submissionId], () => getSubmissionById(token, submissionId), {});
};
