import { Font } from "./../../../../utilities/Constants/Font";
import styled from "styled-components";
import { Palette } from "../../../../theme/Palette";

export const Root = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 32px;
    grid-column-gap: 32px;
`;

export const LogHistory = styled.pre`
    font-family: ${Font.UbuntuMono};
    background: ${Palette.Black100};
    color: ${Palette.White100};
    padding: 16px;
    border-radius: 4px;
`;
