import { TFunction } from "i18next";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSubmission } from "../../../../hooks/api/submission/useSubmission";
import { ContentCard } from "../../../ContentCard/ContentCard";
import { H1 } from "../../../H1/H1";
import { Spinner } from "../../../Spinner/Spinner";
import { LogHistory, Root } from "./SubmissionDetails.styles";
import { SubmissionStatus } from "./SubmissionStatus";

interface IProps {
    readonly submissionId: string;
}

const getValidationStatus = (t: TFunction, isValid: boolean | null | undefined) => {
    if (isValid === undefined) {
        return t("submissionDetailsPage.notBeingValidated");
    }
    if (isValid === null) {
        return t("submissionDetailsPage.pendingValidation");
    }

    if (isValid === false) {
        return t("submissionDetailsPage.invalidSubmission");
    }

    return t("submissionDetailsPage.validSubmission");
};

export const SubmissionDetailsContent: React.FC<React.PropsWithChildren<IProps>> = ({ submissionId }) => {
    const submissionData = useSubmission(submissionId);
    const [t] = useTranslation();

    if (submissionData.status === "loading") {
        return <Spinner />;
    }
    const submission = submissionData.data;
    const pullStatus = submission?.status ?? "pending";
    const eventCode = submission?.eventCode;
    const validationStatus = getValidationStatus(t, submission?.validationStatus?.isValid);
    return (
        <Root>
            <ContentCard>
                <H1>{t("submissionDetailsPage.tag")}</H1>
                <span>{eventCode}</span>
                <H1>{t("submissionDetailsPage.pullStatus")}</H1>
                <SubmissionStatus status={pullStatus} />
                <H1>{t("submissionDetailsPage.pullLogs")}</H1>
                <span>{pullStatus !== "pending" ? <LogHistory>{submission?.saveLogs}</LogHistory> : t("loading")}</span>
                <H1>{t("submissionDetailsPage.validationStatus")}</H1>
                <span>{validationStatus}</span>
            </ContentCard>
        </Root>
    );
};
