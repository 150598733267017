import { Spinner } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    readonly status: "pending" | "fail" | "success";
}

export const SubmissionStatus: React.FC<React.PropsWithChildren<IProps>> = ({ status }) => {
    const [t] = useTranslation();
    if (status === "pending") {
        return <Spinner label={t("submissionsPage.processing")} />;
    } else if (status === "success") {
        return <React.Fragment>{t("submissionsPage.successfullyPulled")}</React.Fragment>;
    } else {
        return <React.Fragment>{t("submissionsPage.failedToPull")}</React.Fragment>;
    }
};
