import * as React from "react";
import { useTranslation } from "react-i18next";
import { AuthenticatedFrame } from "../../../AuthenticatedFrame/AuthenticatedFrame";
import { WithAuth } from "../../../Auth/WithAuth";
import { SEO } from "../../../SEO/SEO";
import { SubmissionDetailsContent } from "./SubmissionDetailsContent";
import { useSubmission } from "../../../../hooks/api/submission/useSubmission";

interface IProps {
    readonly submissionId: string;
}

export const SubmissionDetails: React.FC<React.PropsWithChildren<IProps>> = ({ submissionId }) => {
    const [t] = useTranslation();
    const submission = useSubmission(submissionId);

    const alias = submission.data?.alias ?? submissionId;

    const title = t("submissionDetailsPage.title", { alias });
    const description = t("submissionDetailsPage.description", { alias });

    return (
        <WithAuth>
            <SEO description={description} title={title} />
            <AuthenticatedFrame title={title}>
                <SubmissionDetailsContent submissionId={submissionId} />
            </AuthenticatedFrame>
        </WithAuth>
    );
};
